import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import React, { createContext, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  actionChannelRemove,
  actionChannelUpsert,
  actionDeleteChats,
  actionMessageAdd,
  actionMessageRemove,
} from "./redux/actions/chat";

const fireApp = firebase.initializeApp({
  apiKey: "AIzaSyAcom0N7seGFILTFhFO1Jq_HiILPt1IBbA",
  authDomain: "popin-bdcc2.firebaseapp.com",
  projectId: "popin-bdcc2",
  storageBucket: "popin-bdcc2.appspot.com",
  messagingSenderId: "90899402436",
  appId: "1:90899402436:web:e4497e442e44fa27ad0391",
  measurementId: "G-P8ELNV826T",
});

const auth = firebase.auth();
const database = firebase.database(fireApp);
const authentication = getAuth(fireApp);

// we create a React Context, for this to be accessible
// from a component later
const FirebaseContext = createContext(null);

/*
Firebase DB structures:

- A "party id" is a vendor id or a customer id.
- Each channel is between a vendor and a customer.
- A customer id is a *Firebase* id
- A vendor id is from the Postgres table

/chat/partyToChannelIds:<party_id>/:
  This is a set of channel headers for each party.
  This should be "symmetric", i.e. for each channel we expect there to be
  two entries in this structure.
  Each item is `{"<channel_id>": 0}`, i.e. a fake hashset of ids,
  so that we can idempotently add ids without risking duplicates.

/chat/channels/<channel_id>:
  Each `channel_id` is of the form `b2c:<vendor_id>:<customer_id>`.
*/

const FirebaseProvider = ({ children }) => {
  const dispatch = useDispatch();

  // A mutable array of database refs that we are subscribed to.
  // We store these so we can cleanup subscriptions in `stopChats`.
  const allChatSubscriptions = useRef([]);

  const startChats = useCallback(
    (userId) => {
      console.log("XXX Starting chats for userId:", userId);

      const channelIdsRef = database.ref("/chat/partyToChannelIds/" + userId);

      channelIdsRef.on(
        "child_added",
        (channelIdSnapshot) => {
          const channelId = channelIdSnapshot.key;

          const channelsRef = database.ref("/chat/channels/" + channelId);

          channelsRef.on("value", (channelSnapshot) => {
            // The snapshot of the channel we receive will not contain messages.
            // We will receive this once per channel at startup, and then every time
            // a channel is updated, e.g. when a "seen" value is updated.
            // We need to be careful to preserve our messages when this happens.
            const channel = channelSnapshot.val();

            if (!channel) {
              console.warn("Got null channel snapshot for channelId:", channelId);
              return;
            }

            // Update the Redux store
            dispatch(actionChannelUpsert(channelId, channel));
          });

          const messagesRef = database.ref("/chat/messages/" + channelId);

          messagesRef.on("child_added", (messageSnapshot) => {
            // Update the Redux store
            dispatch(actionMessageAdd(channelId, messageSnapshot.val()));
          });

          messagesRef.on("child_removed", (messageSnapshot) => {
            // Update the Redux store
            dispatch(actionMessageRemove(channelId, messageSnapshot.val()));
          });

          // Mark subscriptions for later cleanup
          allChatSubscriptions.current.push(channelsRef);
          allChatSubscriptions.current.push(messagesRef);
        },
        (err) => {
          console.log("child_added got error", err);
        }
      );

      channelIdsRef.on("child_removed", (channelIdSnapshot) => {
        // Update the Redux store
        dispatch(actionChannelRemove(channelIdSnapshot.key));
      });

      // Mark subscriptions for later cleanup
      allChatSubscriptions.current.push(channelIdsRef);
    },
    [dispatch]
  );

  const stopChats = useCallback(() => {
    console.log("XXX Stopping chats");
    allChatSubscriptions.current.forEach((ref) => ref.off());
    // This action doesn't delete the chats from Firebase, just
    // our local store (e.g. because we are logging out).
    dispatch(actionDeleteChats());
  }, []);

  const sendChatMessage = useCallback((channelId, senderId, text) => {
    const timestamp = new Date().getTime();
    const partyIds = b2cChannelIdToPartyIds(channelId);

    // Send the message and also make sure both parties are aware of the channel.
    database.ref("/chat/partyToChannelIds/" + partyIds[0] + "/" + channelId).set(0);
    database.ref("/chat/partyToChannelIds/" + partyIds[1] + "/" + channelId).set(0);
    database.ref("/chat/messages/" + channelId).push({ timestamp, senderId, text });

    setChatSeen(channelId, timestamp);
  }, []);

  const setChatSeen = useCallback((channelId, timestamp) => {
    database.ref("/chat/channels/" + channelId + "/businessSeen").set(timestamp);
  }, []);

  const deleteChat = useCallback((channelId, userId) => {
    // For now we don't delete chats, we just tombstone them by setting
    // the `deleted` key. This means that we can keep the data around in
    // case we need to check for abuse.
    database.ref("/chat/channels/" + channelId + "/deleted").set({
      userId,
      time: new Date().getTime(),
    });
  }, []);

  const funcs = {
    startChats,
    stopChats,
    sendChatMessage,
    setChatSeen,
    deleteChat,
  };

  return <FirebaseContext.Provider value={funcs}>{children}</FirebaseContext.Provider>;
};

const b2cChannelIdToPartyIds = (channelId) => {
  const parts = channelId.split(":");
  parts.shift();
  return parts;
};

export { authentication, database, auth, FirebaseContext, FirebaseProvider };
