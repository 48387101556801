// ** Reducers Imports
import chat from "./chat";
import layout from "./layout";
import navbar from "./navbar";

const rootReducer = {
  navbar,
  layout,
  chat,
};

export default rootReducer;
