const ACTION_CHANNEL_UPSERT = "chats/channelUpsert";
const ACTION_CHANNEL_REMOVE = "chats/channelRemove";
const ACTION_DELETE_CHATS = "chats/deleteChats";
const ACTION_MESSAGE_ADD = "chats/messageAdd";
const ACTION_MESSAGE_REMOVE = "chats/messageRemove";

const actionChannelUpsert = (channelId, channel) => {
  return {
    type: ACTION_CHANNEL_UPSERT,
    payload: {
      channelId,
      channel,
    },
  };
};

const actionChannelRemove = (channelId) => {
  return {
    type: ACTION_CHANNEL_REMOVE,
    payload: {
      channelId,
    },
  };
};

const actionDeleteChats = () => {
  return {
    type: ACTION_DELETE_CHATS,
  };
};

const actionMessageAdd = (channelId, message) => {
  return {
    type: ACTION_MESSAGE_ADD,
    payload: {
      channelId,
      message,
    },
  };
};

const actionMessageRemove = (channelId, message) => {
  return {
    type: ACTION_MESSAGE_REMOVE,
    payload: {
      channelId,
      message,
    },
  };
};

export {
  ACTION_CHANNEL_UPSERT,
  ACTION_CHANNEL_REMOVE,
  ACTION_DELETE_CHATS,
  ACTION_MESSAGE_ADD,
  ACTION_MESSAGE_REMOVE,
  actionChannelUpsert,
  actionChannelRemove,
  actionDeleteChats,
  actionMessageAdd,
  actionMessageRemove,
};
