import { List, Map } from "immutable";

import {
  ACTION_CHANNEL_REMOVE,
  ACTION_CHANNEL_UPSERT,
  ACTION_DELETE_CHATS,
  ACTION_MESSAGE_ADD,
  ACTION_MESSAGE_REMOVE,
} from "../actions/chat";

// The state is a mapping from channelId to channel
const initialState = Map({});

// This returns the negative so that we can sort chat headers in
// descending time order.
const getMinusLastMessageTimestamp = (channel) => {
  return -getLastMessageTimestamp(channel);
};

const getLastMessageTimestamp = (channel) => {
  return channel.getIn(["messages", -1], 0).timestamp ?? 0;
};

const UNKNOWN_CHANNEL = Map({
  businessId: null,
  businessName: "Unknown",
  businessAvatar: null,
  customerId: null,
  customerName: "Unknown",
  customerAvatar: null,
  messages: List([]),
});

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CHANNEL_UPSERT: {
      let { channelId, channel } = action.payload;

      // The channel may or may not exist, hence the "upsert".
      // Find existing messages, if any.
      const messages = (() => {
        const prevChannel = state.get(channelId);
        if (!prevChannel) {
          return List([]);
        }

        return prevChannel.get("messages");
      })();

      // Turn the plain JS object into a Map and add the empty messages List
      const newChannel = Map(channel).set("messages", messages);
      return state.set(channelId, newChannel);
    }

    case ACTION_CHANNEL_REMOVE: {
      let { channelId } = action.payload;

      return state.delete(channelId);
    }

    case ACTION_DELETE_CHATS: {
      return Map({});
    }

    case ACTION_MESSAGE_ADD: {
      const { channelId, message } = action.payload;

      // Find the channel with the message. If there is none (this might be possible)
      // if the messages `child_added` callback is fired before the
      // channels `child_added` callback) then add a default; we can fill in the details
      // when (hopefully) they come through.
      const prevChannel = state.get(channelId) || UNKNOWN_CHANNEL;

      // TODO: There should be a more efficient way to update in-place
      return state
        .set(
          channelId,
          prevChannel.set(
            "messages",
            prevChannel
              .get("messages")
              .push(message)
              .sortBy((message) => message.timestamp)
          )
        )
        .sortBy(getMinusLastMessageTimestamp);
    }

    case ACTION_MESSAGE_REMOVE: {
      const { channelId, message } = action.payload;

      // Find the channel with the message. If there is none (this might be possible)
      // if the messages `child_added` callback is fired before the
      // channels `child_added` callback) then add a default; we can fill in the details
      // when (hopefully) they come through.
      const prevChannel = state.get(channelId) || UNKNOWN_CHANNEL;

      // TODO: There should be a more efficient way to update in-place
      return state
        .set(
          channelId,
          prevChannel.set(
            "messages",
            prevChannel.get("messages").filterNot((message_) => message.timestamp == message_.timestamp)
          )
        )
        .sortBy(getMinusLastMessageTimestamp);
    }

    default:
      return state;
  }
};
