// ** React Imports
// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss";
// ** Ripple Button
// import "./@core/components/ripple-button";
// ** PrismJS
import "prismjs";
import "prismjs/components/prism-jsx.min";
import "prismjs/themes/prism-tomorrow.css";
import { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
// ** Redux Imports
import { Provider } from "react-redux";
// ** Intl & ThemeColors Context
import { ToastContainer } from "react-toastify";

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";
import "./@core/scss/core.scss";
import "./assets/Fonts/Red_Hat_Display/static/RedHatDisplay-Bold.ttf";
import "./assets/Fonts/Red_Hat_Display/static/RedHatDisplay-Regular.ttf";
// Tailwind
import "./assets/css/main.css";
import "./assets/scss/style.scss";
import { FirebaseProvider } from "./firebase";
import "./index.css";
import { store } from "./redux/store";
// ** Service Worker
import * as serviceWorker from "./serviceWorker";
import { ThemeContext } from "./utility/context/ThemeColors";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));
// firebase.initializeApp({
//   apiKey: "AIzaSyAcom0N7seGFILTFhFO1Jq_HiILPt1IBbA",
//   authDomain: "popin-bdcc2.firebaseapp.com",
//   projectId: "popin-bdcc2",
//   storageBucket: "popin-bdcc2.appspot.com",
//   messagingSenderId: "90899402436",
//   appId: "1:90899402436:web:e4497e442e44fa27ad0391",
//   measurementId: "G-P8ELNV826T",
// });

ReactDOM.render(
  <Provider store={store}>
    <FirebaseProvider>
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Suspense>
    </FirebaseProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
